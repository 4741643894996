/* =======================
Foundation
========================*/
$(document).foundation();


/* =======================
FAQs
========================*/
$(".faq-question a").click(function(e) {
    $(this).toggleClass("active");
});


/* =======================
Background Loop
========================*/
$(function() {
    "use strict";
    var x = 0;
    setInterval(function() {
        x -= 1;
        $('#section-testimonial').css('background-position', x + 'px 0');
    }, 50);
})


/* =======================
Sliders
========================*/
/* Testimonial Slider */
$('.testimonial-slider').slick({
    dots: false,
    speed: 1200,
    slidesToShow: 1,
    touchMove: false,
    slide: 'li',
    dots: true,
    arrows: false,
});

/* Testimonial Slider */
$('.nutrition-testimonial-slider').slick({
    autoplay: true,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    touchMove: true,
    arrows: false,
});


/* =======================
Parallax
========================*/
// $('.parallax-window').parallax({
//     imageSrc: "/images/cssimg/sections/bg_dumbbells.jpg"
// });
// $('.parallax-window-contact').parallax({
//     imageSrc: "/images/contact-section-bg.jpg"
// });

// $('.parallax-window-portfolio').parallax({
//     imageSrc: "/images/portfolio-bg.jpg"
// });


/* =======================
Scroll To Top
========================*/
$(".feature ul li").each(function() {
    $(this).find("a").click(function() {
        var hrf = $(this).attr("href");
        $('html, body').animate({
            scrollTop: $(hrf).offset().top
        }, 1500);
    });
});

$(".scrollToTop").click(function(e) {
    $('html, body').animate({
        scrollTop: $('body').offset().top - 20
    }, 1500);
});

$(".scroll-to").click(function(e) {
  $('html, body').animate({
    scrollTop: $(e.target.hash).offset().top
  }, 1500);
});

/* =======================
Wow CSS Animation Scroller
========================*/
var wow = new WOW({
     animateClass: 'animated',
     offset: 100,
     iteration: 1,
     callback: function(box) {
         console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
     }
 });
 wow.init();

/* =======================
FAQs
========================*/
$(function(){
    $('.accordion dd').css('display','none');
    var link = $('.accordion dt > a');

    link.on('click', function(e) {

        e.preventDefault();
        var a = $(this).parent();

        $('.accordion dd').not(a).slideUp('300');

        if ($(a).hasClass('active')) {
            $(a).next('dd').slideUp('300').prev('dt').removeClass();
        }
        else {
            $('.accordion dt').removeClass();
            $(a).next('dd').slideDown('300').prev('dt').addClass('active');
        }
    });
});

/* =======================
Gallery
========================*/
$('.gallery-wrap').magnificPopup({
  delegate: 'a',
  type: 'image',
  gallery: {
    enabled: true
  }

});

/* =======================
Blog Masonry
========================*/
// $(window).load(function(){
//   $('.blog-lists').masonry({
//     itemSelector: '.blog-details',
//     columnWidth: '.grid-sizer',
//     percentPosition: true
//   });
// });


/* =======================
Background image rotator
========================*/
$(function(){
    var infiniteRotatorItems = $('#rotating-item-wrapper').data('rotator-items');

    var addInfiniteRotatorItems = function() {
      for(var i = infiniteRotatorItems-1; i > 0; i--) {
        $('.rotating-item.img-0').after('<div class="rotating-item img-'+ i +'"></div>');
      }
    };

    addInfiniteRotatorItems();

    var InfiniteRotator = {
        init: function() {
            //initial fade-in time (in milliseconds)
            var initialFadeIn = 1000;

            //interval between items (in milliseconds)
            var itemInterval = 7000;

            //cross-fade time (in milliseconds)
            var fadeTime = 2500;

            //count number of items
            var numberOfItems = $('.rotating-item').length;

            //set current item
            var currentItem = 0;

            //show first item
            // $('.rotating-item').eq(currentItem).fadeIn(initialFadeIn);

            //loop through the items
            var infiniteLoop = setInterval(function() {
                $('.rotating-item').eq(currentItem).fadeOut(fadeTime);

                if (currentItem == numberOfItems - 1) {
                    currentItem = 0;
                } else {
                    currentItem++;
                }
                $('.rotating-item').eq(currentItem).fadeIn(fadeTime);

            }, itemInterval);
        }
    };

    InfiniteRotator.init();

});


/* =======================
Show options for choosing
nutrition coaching type
========================*/
// $('.show-options > a').click(function(e) {
//   e.preventDefault();
//   $(this).toggleClass('open');

//   var $nutritionAddServices = $('.nutrition-service-additional .transition-wrap');

//   if($nutritionAddServices.hasClass('open')) {
//     $nutritionAddServices.height(0);
//     $nutritionAddServices.toggleClass('open');
//     return;
//   }

//   $nutritionAddServices.height($nutritionAddServices[0].scrollHeight);
//   $nutritionAddServices.toggleClass('open');
// });


/* =======================
Team Nutrition Bios
========================*/
$('.coach .button').magnificPopup({
  type:'inline',
  midClick: true
});

/* ==================================================
Netlify AJAX Form submission:
Grabs action attribute from form and submits to
Netlify via AJAX, then fades out form and displays
thanks message
===================================================*/
var $ajaxFormWrap = $('.ajax-form-wrapper');
var $ajaxFormMessage = $('.ajax-form-thanks');
var $ajaxFormContainer = $('.ajax-form-container');
var $ajaxForm = $('.ajax-form');

if($ajaxFormWrap.length) {
  $('.ajax-form').on("submit", function(event) {
    event.preventDefault();
    $.post($ajaxForm.attr("action"), $ajaxForm.serialize()).then(function() {
      $ajaxFormWrap.addClass('hide');
      $ajaxFormMessage.fadeIn();
      ga('send', {
        hitType: 'event',
        eventCategory: 'Form',
        eventAction: 'Submit',
        eventLabel: $ajaxForm.attr('name')
      });
    });
  });
}
